<template>
  <div>
    <a-page-header title="通勤巴士管理" class="hearStyle" />
    <a-button type="primary" @click="addData"> <a-icon type="plus" /> 添加 </a-button>
    <a-table
      :columns="columns"
      style="margin-top: 10px"
      :data-source="tableData"
      rowKey="id"
      :pagination="false"
      bordered
      :scroll="{ x: 1500 }"
    >
      <span slot="linename" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{ record.linename }}
          </template>
          <span class="field">{{ record.linename }}</span>
        </a-tooltip>
      </span>
      <p slot="busState" slot-scope="text, record">{{ record.busState === 0 ? "启用" : "禁用" }}</p>
      <span slot="operation" slot-scope="text, record" class="action">
        <a href="javascript:;" @click="check(record)">查看</a>
        <a href="javascript:;" @click="edmit(record)">编辑</a>
        <a href="javascript:;" @click="deleteData(record)">删除</a>
      </span>
      <div slot="sequence" slot-scope="text, record">
        <a-input
          type="Number"
          max="99999"
          oninput="if(value.length>5)value=value.slice(0,5)"
          @blur="sequenceChange(record, text)"
          v-model="text"
        />
      </div>
    </a-table>
    <div class="pageSwitch" v-show="total != 0">
      <a-pagination
        show-size-changer
        :page-size="pageSize"
        :show-total="(total) => `共 ${total} 条`"
        show-quick-jumper
        :default-current="page"
        :total="total"
        @showSizeChange="onShowSizeChange"
        @change="pageOnChange"
      />
    </div>
    <a-modal v-model="visibleDelete" title="提示" @ok="confirmDelete">
      <span>线路删除无法恢复，确定删除吗？</span>
    </a-modal>
    <a-modal v-model="visibleDeleteFalse" title="提示" @ok="confirmDeleteFalse">
      <span>请先禁用路线后，再操作删除。</span>
    </a-modal>
  </div>
</template>
<script>
import * as api from "@/api/userMessage";
export default {
  data() {
    return {
      visibleDelete: false,
      visibleDeleteFalse: false,
      deleteId: "",
      childType: false,
      childcheckType: false,
      ModalTitle: "",
      total: 0,
      page: 1,
      pageSize: 10,
      columns: [
        { title: "线路名称", dataIndex: "linename", key: "linename", width: 200 },
        { title: "始发站", dataIndex: "firstsite", key: "firstsite", width: 200 },
        { title: "终点站", dataIndex: "endsite", key: "endsite", width: 200 },
        { title: "首班车时间", dataIndex: "firstbustime", key: "firstbustime", width: 120 },
        { title: "末班车时间", dataIndex: "endbustime", key: "endbustime", width: 120 },
        { title: "运营时间", dataIndex: "operationtime", key: "operationtime", width: 150 },
        {
          title: "排序",
          dataIndex: "sequence",
          key: "sequence",
          scopedSlots: { customRender: "sequence" },
          width: 140,
        },
        {
          title: "状态",
          dataIndex: "busState",
          key: "busState",
          width: 100,
          scopedSlots: { customRender: "busState" },
        },
        { title: "时间", dataIndex: "updatetime", key: "updatetime", width: 170 },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          width: 170,
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    //排序change时间
    async sequenceChange(val, text) {
      if ((val.sequence != text && text > 0) || (text == "" && val.sequence != text)) {
        let obj = {
          id: val.id,
          sequence: text,
        };
        const res = await api.setSequence(obj);
        if (res.code === "200") {
          this.getTableList();
          this.$message.success("操作成功!");
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    //查看打开弹窗
    check(val) {
      this.$router.push({
        path: "/commute/checkModal",
        query: { id: val.id },
      });
    },
    //点击添加打开弹出
    addData() {
      this.$router.push({
        path: "/commute/addModal",
      });
    },
    //删除
    deleteData(val) {
      if (val.busState === 0) {
        this.visibleDeleteFalse = true;
      } else {
        this.visibleDelete = true;
        this.deleteId = val.id;
      }
    },

    confirmDeleteFalse() {
      this.visibleDeleteFalse = false;
    },
    async confirmDelete() {
      const res = await api.deletedCommuterBus({ id: this.deleteId });
      if (res.code === "200") {
        this.$message.success("删除成功!");
        this.visibleDelete = false;
        this.getTableList();
      } else {
        this.$message.error(res.msg);
      }
    },
    //编辑添加打开弹出
    edmit(val) {
      this.$router.push({
        path: "/commute/addModal",
        query: { id: val.id },
      });
    },
    //获取列表数据
    async getTableList() {
      let obj = {
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      const res = await api.getBusList(obj);
      if (res.code === "200") {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
    },
    onShowSizeChange(current, pageSize) {
      this.page = current;
      this.pageSize = pageSize;
      this.getTableList();
    },
    pageOnChange(current) {
      this.page = current;
      this.getTableList();
    },
  },
};
</script>
<style lang="scss" scoped>
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 40px;
}
.pageSwitch {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
.hearStyle {
  padding-left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 237, 240);
}
</style>